.box__CompanyLogo {
    width: 198px;
    height: 198px;
    border: 2px dotted black;
    border-radius: 100%;

}

.box__circle_number {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    border:1.3px solid black;
}


#box__numbers {
    margin-top: 10%;
    width: 25%;
    padding: 15px;
}

#box__typographies {
    width: 75%;
    margin-top: 10%;
}

.typography__stepperTitle {
    color: #000;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px!important;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.typography__steppersubtitle {
    color: var(--Neutral-Mid-Grey, #8B8B8B);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px!important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.035px;
}