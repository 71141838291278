.textfield__basicInfo {
    width: 100%;
}

.typographyOfHeader {
    font-size: 20px;
    text-align: left;
    width: 100%;

}

.customTableBody {
    margin-bottom: 1rem !important; /* Adjust this value as needed */
}