/* BOXES */

.main_box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.box__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    user-select: none;
}

.box__postings {
    width: '100%'
}

/* BUTTONS */
.button__filterButtons {
    width: 78px !important;
    height: 39px !important;
    border-radius: 61px !important;
    border: 1px solid var(--primary-3, #38C1DF) !important;
    color: black;
    text-transform: none !important
}

/* TYPOGRAPHIES */
.typography__header {
    color: var(--Neutral-Mid-Grey, #8B8B8B);
    text-align: center;

    /* Subheading-3 */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.08px;
}

.chat-container {
    height: 60vh;/* Set a maximum height for your chat containerheight: max(100%, 600px); */
    overflow-y: scroll; /* Add a scroll bar only when needed */
    scrollbar-width: none; /* Hide the scrollbar for Firefox */
    -ms-overflow-style: none;
    background-color: rgba(217, 217, 217, 0.20);
    /* margin-bottom: 1rem; 
    padding: .5rem; */
  }
  
  /* Hide the scrollbar for WebKit browsers (Safari, Chrome) */
  /* .chat-container::-webkit-scrollbar {
    width: 0;
    display: none;
  } */

  .entireChatPlacement {
    width: 100%;
    height: max(100%, 600px);
    background-color: rgba(217, 217, 217, 0.20);
  }