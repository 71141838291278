.pp-container {
	width: auto;
}

/* .pp-container-cropper {

  margin-left: 0%;
  height: 40%;
  width: 20%;
  justify-content: center;
  align-items: center;
  padding: 10px;
} */
.pp-container-cropper {
	display: 'flex';
	flex-direction: column;
	gap: '0.5rem';
	width: 30%;
	z-index: 10;
}

.pp-cropper {
	height: 76%;
	position: relative;
	justify-content: center;
	align-items: center;
}

/* .slider {
	height: 10%;
	display: flex;
	align-items: center;
	margin: auto;
	width: 60%;
} */

.container-buttons {
	border: 1px solid #f5f5f5;
	height: 10%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.editprofile-img-holder {
	margin: 0 auto;
	width: 100%;
	margin-bottom: 5%;
	left: 50%;
}

.editprofile-profileimage {
	width: 120px;
	height: 120px;
	margin: 0 auto;
	border-radius: 100%;
	/* box-shadow: 0 0 8px 3px #b8b8b8; */
	position: relative;
}

.editprofile-profileimage .editprofile-img {
	width: 120px;
	height: 120px;
	border-radius: 100%;
}
/* the pen icon position */
.editprofile-profileimage #editprofile-image-edit {
	position: absolute;
	top: 5px;
	right: 5px;
	/* border: 1px solid; */
	border-radius: 50%;
	/* padding: 11px; */
	height: 40px;
	width: 40px;
	display: flex !important;
	align-items: center;
	justify-content: center;
	background-color: #dadada;
	color: black;
	box-shadow: 0 0 8px 3px #b8b8b8;
}

#editprofile-image-edit {
	width: 50px;
	height: 50px;
	border-radius: 100%;
	background-color: #dadada;
	position: absolute;
	/* z-index: 1; */
	margin-top: 80px;
	cursor: pointer;
  }

  
.editprofile-top-navigation-bar {
	position: relative;
	width: 87%;
	background-color: #f2f2f2;
	height: 20%;
	float: right;
  }
  
  
  .editprofile-Line-separator {
	height: 100px;
	width: 500px;
	background-color: black;
  }
  
  .editprofile-button-line {
	border: none;
	justify-content: center;
	text-align: center;
	align-items: center;
  }
  
  .editprofile-buttons {
	/* float: right; */
	margin: 0px 0px 30px 750px;
	width: 30px;
	height: 30px;
	border-radius: 25%;
	position: relative;
	border: none;
  }
  
  .editprofile-profileimage {
	width: 140px;
	height: 140px;
	margin: 0 auto;
	border-radius: 100%;
	/* box-shadow: 0 0 8px 3px #B8B8B8; */
	position: relative;
  }
  
  .editprofile-profileimage .editprofile-img {
	width: 140px;
	height: 140px;
	border-radius: 100%;
  }
  
  .editprofile-profileimage #editprofile-image-edit {
	position: absolute;
	top: 0px;
	right: -10px;
	/* border: 1px solid; */
	border-radius: 50%;
	/* padding: 11px; */
	height: 50px;
	width: 50px;
	display: flex !important;
	align-items: center;
	justify-content: center;
	background-color: #dadada;
	color: black;
	box-shadow: 0 0 8px 3px #B8B8B8;
  }
  
  .select_input {
	background-color: white;
  }

  

.editprofile-img-holder {
	margin: 0 auto;
	width: 100%;
	margin-bottom: 5%;
	left: 50%;
  }
  
  #editprofile-image-edit {
	width: 50px;
	height: 50px;
	border-radius: 100%;
	background-color: #dadada;
	position: absolute;
	/* z-index: 1; */
	margin-top: 80px;
	cursor: pointer;
  }
  
