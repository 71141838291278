.Optionsbutton:focus {
    color: black;
    justify-content: center;
    text-align: center;
    padding-right: 20px;
    padding-left: 20px;
    width: auto;
    height: 35px;
    margin-right: 1rem;
    background: rgba(56, 193, 223, 0.3);
    border-radius: 15px;
    border: none;
    font-family: 'Roboto', sans-serif;
    margin-top: 1rem;
    margin-bottom: .5rem;
  }

  .Optionsbutton {
    color: black;
    justify-content: center;
    text-align: center;
    padding-right: 20px;
    padding-left: 20px;
    width: auto;
    height: 35px;
    margin-right: 1rem;
    border-radius: 20px;
    border: 1px solid #2AA8C4;
    font-family: 'Roboto', sans-serif;
    background-color: transparent;
    margin-top: 1rem;
    margin-bottom: .5rem;
  }

  
  .optionsFocusButton {
    color: black;
    justify-content: center;
    text-align: center;
    padding-right: 20px;
    padding-left: 20px;
    width: auto;
    height: 35px;
    margin-right: 1rem;
    background: rgba(56, 193, 223, 0.3);
    border-radius: 15px;
    border: none;
    font-family: 'Roboto', sans-serif;
    margin-top: 1rem;
    margin-bottom: .5rem;
  }