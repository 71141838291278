.large-1440dp {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      196.78deg,
      rgba(255, 255, 255, 0.5),
      rgba(217, 217, 217, 0.5)
    );
    width: 1440px;
    height: 1024px;
    overflow: hidden;
  }
  .career-carve-logo-1-removebg-icon {
    position: relative;
    width: 46.37px;
    height: 50.59px;
    flex-shrink: 0;
    object-fit: cover;
  }
  .career-carve-logo-1-removebg-wrapper {
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .dashboard-icon,
  .profile-icon,
  .settings-icon {
    position: relative;
    width: 25px;
    height: 23.93px;
    flex-shrink: 0;
  }
  .profile-icon,
  .settings-icon {
    height: 25px;
  }
  .settings-icon {
    width: 24px;
    height: 24px;
    overflow: hidden;
  }
  .dashboard-parent,
  .nav-bar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 42px;
  }
  .nav-bar {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f7f7f7;
    box-shadow: var(--subtle-shadow);
    height: 1024px;
    padding: 16px 6px;
    box-sizing: border-box;
    align-items: center;
    gap: 96px;
  }
  .support-tickets {
    position: relative;
    font-weight: 400;
font-size: 32px;
line-height: 39px;
  }
  .category,
  .raised-by {
    position: relative;
    letter-spacing: 0.01em;
    display: inline-block;
    width: 160px;
    flex-shrink: 0;
  }
  .raised-by {
    width: 220px;
  }
  .category-parent {
    width: 1127px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    color: #8B8B8B;
  }
  .frame-child {
    position: relative;
    box-sizing: border-box;
    width: 1114.01px;
    height: 1px;
    flex-shrink: 0;
  }
  .aryan-malik {
    margin: 0;
    color: var(--neutral-black);
  }
  .aryan2019vitstudentacin {
    margin: 0;
    color: #545454;
  }
  .aryan-malik-aryan2019vitstude-container {
    position: relative;
    letter-spacing: 0.01em;
    display: inline-block;
    width: 220px;
    flex-shrink: 0;
    color: var(--neutral-dark-grey);
  }
  .time-remaining-3 {
    margin: 0;
    color: var(--neutral-dark-grey);
  }
  .open-time-remaining-container {
    position: relative;
    letter-spacing: 0.01em;
    display: inline-block;
    width: 160px;
    flex-shrink: 0;
    color: #08ab3f;
  }
  .frame-item {
    position: absolute;
    top: 7px;
    left: 45px;
  }
  .frame-wrapper {
    position: relative;
    width: 220px;
    height: 53px;
    flex-shrink: 0;
  }
  .category-group {
    width: 1127px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  .unresolved {
    position: relative;
    letter-spacing: 0.01em;
    color: var(--red);
    display: inline-block;
    width: 160px;
    flex-shrink: 0;
  }
  .frame-parent,
  .support-tickets-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-parent {
    gap: 16px;
    text-align: center;
    font-size: var(--subheading-3-size);
  }
  .support-tickets-parent {
    position: absolute;
    top: 56px;
    left: 10rem;
    gap: 40px;
  }

  .student-details-parent {
    top: 56px;
    left: 10rem;
    gap: 40px;
    width: 80%;
    margin: 0 auto;
  }
  .support-ticket-list {
    position: relative;
    background-color: var(--neutral-white);
    width: 100%;
    height: 1024px;
    overflow: hidden;
    text-align: left;
    font-size: var(--heading-4-size);
    color: var(--neutral-black);
    font-family: var(--heading-4);
  }

  .st-table-header
  {

  }

  .st-table-body
  {
    width: 80%;
    justify-content: center;
    text-align: center;
  }

  .st-table-dashboard {
    width: 80%;
    justify-content: left;
    text-align: left;
  }

  .category
  {
    border: none;
  }

  .st-align-all-center
  {
    /* justify-content:left; */
    align-items: left;
    text-align: center;
    align-self: center;
    align-content: center;
  }

  .btn-style-st
  {
    background: rgba(56, 193, 223, 0.2);
    border-radius: 40px;
    color: black;
    border: none;
    width: 143px;
    height: 39px;
  }

  .secondline-status
  {
    color: #545454;
    justify-content: flex-end;
    align-items:flex-end;
    align-self:flex-end;
    align-content:flex-end;
    text-align: center;
    font-size: 16px;
  }

  .searchTab {
    background: #E6E6E6;
border-radius: 92px;
width: 100%;
  }

  .searchingText {
    align-items: center;
    justify-content: center;
    /* padding-top: .3rem; */
    padding-left: .5rem;
    /* margin-bottom: auto; */

  }

  .searchingText:focus {
    outline: none;
  }
  