.box__CompanyLogo {
    width: 198px;
    height: 198px;
    border: 2px dotted black;
    border-radius: 100%;

}

.box__circle_number {
    color: var(--primary1);
    background: linear-gradient(0deg, rgba(56, 193, 223, 0.10) 0%, rgba(56, 193, 223, 0.10) 100%), #FFF;
    border-radius: 100%;
    width: 40px;
    height: 40px;

}


#box__numbers {
    margin-top: 10%;
    width: 25%;
    padding: 15px;
}

#box__typographies {
    width: 75%;
    margin-top: 10%;
}

.typography__stepperTitle {
    color: #000;
    /* Title Medium - Roboto Medium 16/24 . +0.15 */
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
    /* 120% */
    letter-spacing: 0.15px;
}

.typography__steppersubtitle {
    color: var(--Neutral-Mid-Grey, #8B8B8B);

    /* Subheading-4 */
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.035px;
}