/*===== VARIABLES CSS =====*/
:root {
  /*===== Colores =====*/
  --first-color: #4A44A5;
  --input-color: #80868B;
  --border-color: #DADCE0;
  --before-button-hover: #6C63FF;
  --after-button-hover: #4A44A5;
  --center-input-label: .7rem;
  --background-color: #03ac98;
  --top-background-color: #fff;
  --body-background-color: #dadada;
  --cancel-button-no-border-before: #2990ea;
  --cancel-button-no-border-after: #275ed4;
  --submit-btn-color: #38C1DF;
  /*===== font size =====*/
  --body-font: 'Roboto', sans-serif;
  --normal-font-size: 16vw;
  --small-font-size: .75rem;
  --medium-font-size: .9rem;

  --margin-top-of-logo: 35px;
  /* fonts */
  --display-medium-45-52-0: Inter;

  /* font sizes */
  --body-large-16-24-05-size: 16px;
  --headline-xs-20-36-0-size: 20px;
  --headline-small-24-32-0-size: 24px;
  --display-medium-45-52-0-size: 45px;

  /* Colors */
  --neutral-white: #fff;
  --color-black: #000;
  --primary: #561d5e;
  --primary1: #1C8EA8;
  --neutral-dark-grey: #545454;

  /* Gaps */
  --gap-5xl: 24px;

  /* Paddings */
  --padding-3xs: 10px;

  /* border radiuses */
  --br-xl: 20px;
  --br-11xl: 30px;
}


.Login-button {
  margin-bottom: 3rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  color: white;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 465px;
  height: 45px;
  background: linear-gradient(99.23deg, #2AA8C4 -9.01%, #38C1DF 103.32%);
  border-radius: 11px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  border: none;
  font-family: 'Roboto', sans-serif;
}

.Login-button:disabled {
  background: #8B8B8B;
  margin-top: 3rem;
  margin-bottom: 3rem;

  color: white;
  justify-content: center;
  text-align: center;
  padding-right: 10px;
  padding-left: 10px;
  width: 227px;
  height: 30px;
  border-radius: 10px;
  border: none;
  font-family: 'Roboto', sans-serif;
}

.Submitbutton {
  margin-top: 3rem;
  margin-bottom: 3rem;

  color: white;
  justify-content: center;
  text-align: center;
  padding-right: 10px;
  padding-left: 10px;
  width: 227px;
  height: 30px;
  background: linear-gradient(99.23deg, #2AA8C4 -9.01%, #38C1DF 103.32%);
  border-radius: 10px;
  border: none;
  font-family: 'Roboto', sans-serif;
}

/* .Optionsbutton {


  color: black;
  justify-content: center;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  width: auto;
  height: 35px;
  margin-right: 1rem;
  border-radius: 20px;
  border: 1px solid #2AA8C4;
  font-family: 'Roboto', sans-serif;
} */

.Previousbutton {
  color: #2AA8C4;
  justify-content: center;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  width: 184.5px;
  height: 35px;
  margin-right: 1rem;
  /* background: linear-gradient(99.23deg, #2AA8C4 -9.01%, #38C1DF 103.32%); */
  border-radius: 11px;
  border: 1px solid #2AA8C4;
  font-family: 'Roboto', sans-serif;
}

.Previousbutton:disabled {
  color: white;
  justify-content: center;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  width: 184.5px;
  height: 35px;
  margin-right: 1rem;
  background: lightgrey;
  border-radius: 11px;
  /* border: 1px solid #2AA8C4; */
  font-family: 'Roboto', sans-serif;
}

.Rephrasebutton {
  color: white;
  justify-content: center;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  width: 184.5px;
  height: 40px;
  margin-right: 1rem;
  background: linear-gradient(99.23deg, #2AA8C4 -9.01%, #38C1DF 103.32%);
  border-radius: 11px;
  /* border: 1px solid #2AA8C4; */
  font-family: 'Roboto', sans-serif;
}

.RephrasebuttonNoBackground {
  color: #1C8EA8;
  justify-content: center;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  width: 184.5px;
  height: 35px;
  margin-right: 1rem;
  border-radius: 11px;
  font-family: 'Roboto', sans-serif;
}

.Rephrasebutton:disabled {
  color: white;
  justify-content: center;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  width: 184.5px;
  height: 40px;
  margin-right: 1rem;
  background: #8B8B8B;
  border-radius: 11px;
  /* border: 1px solid #2AA8C4; */
  font-family: 'Roboto', sans-serif;
}

textarea:focus::placeholder {
  color: transparent;
}

.login-Container {
  width: max(100%, 1200px);
  height: 100%;
}



.StartSEssionbutton {
  /* margin-top: 3rem; */
  /* margin-bottom: 3rem; */
  text-align: center;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 2rem; */
  display: flex;
  color: white;
  /* flex-direction: row; */
  justify-content: center;
  font-size: 16px;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 80%;
  max-width: 400px;
  min-width: 100px;
  height: 40px;
  background: linear-gradient(99.23deg, #2AA8C4 -9.01%, #38C1DF 103.32%);
  border-radius: 11px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  border: none;
  font-family: 'Roboto', sans-serif;
}

.StartSEssionbutton:disabled {
  /* margin-top: 3rem; */
  /* margin-bottom: 3rem; */
  text-align: center;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 2rem; */
  display: flex;
  color: white;
  /* flex-direction: row; */
  justify-content: center;
  font-size: 16px;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 80%;
  max-width: 350px;
  min-width: 100px;
  height: 40px;
  background: #80868B;
  border-radius: 11px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  border: none;
  font-family: 'Roboto', sans-serif;
}

.StartSEssionbutton-outline {
  /* margin-top: 3rem; */
  /* margin-bottom: 3rem; */
  text-align: center;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 2rem; */
  display: flex;
  color: #1C8EA8;
  /* flex-direction: row; */
  justify-content: center;
  font-size: 16px;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 80%;
  max-width: 400px;
  min-width: 100px;
  height: 40px;
  background: white;
  border-radius: 11px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  border: 1px solid #1C8EA8;
  font-family: 'Roboto', sans-serif;
}

.StartSEssionbutton-outline:disabled {
  /* margin-top: 3rem; */
  /* margin-bottom: 3rem; */
  text-align: center;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 2rem; */
  display: flex;
  color: white;
  /* flex-direction: row; */
  justify-content: center;
  font-size: 16px;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 80%;
  max-width: 350px;
  min-width: 100px;
  height: 40px;
  background: #80868B;
  border-radius: 11px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  border: none;
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
}

#root {
  overscroll-behavior-y: none;
}

/* scroll bar */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #657080;
  border-radius: 5px;
}

code,
kbd,
samp,
pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:focus {
  outline: none;
}

/**
 * needed for safari. Safari
 * adds background color on its own
 * on hls-viewer on fullscreen
 */
#hls-viewer-dark:fullscreen {
  background-color: black !important;
}

#hls-viewer-light:fullscreen {
  background-color: white !important;
}

.button-container {
  align-items: center;
  justify-content: center;
  border: 1px solid #000000;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  padding: 10px;
}

.button-title {
  color: #545454;
  text-transform: none;

}

.button-store-name {
  font-size: 22px;
  color: #000000;
  font-weight: 400 !important;
}

.signup-button {
  background: none;
  border: none;
  color: #1C8EA8;
  margin-left: .5rem;
  text-decoration: none;
}

.pwr-have-account-descr {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
  letter-spacing: 0.5px;

  /* Neutral Mid Grey */

  color: #8B8B8B;

}

.submitBtn {
  background-color: var(--submit-btn-color);
  width: 100%;
  height: 40px;
  border-color: var(--submit-btn-color);
  border: 0;
  border-radius: 10px;
  color: white;
  bottom: 0;
  /* margin-top: 1rem; */
}

.submitRdBtn {
  background-color: var(--primary);
  width: 100%;
  height: 40px;
  border-color: var(--primary);
  border: 0;
  border-radius: 10px;
  color: white;
  bottom: 0;
  /* margin-top: 1rem; */
}

.MuiTableCell-root {
  border-bottom: none;
}

.feedbackFileName {
  margin: 0;
  color: #1C8EA8;
}


body {
  margin: 0;
  padding: 0;
  font-family: var(--body-font);
}

.show-hide-button {
  background: none;
  border: none;
  color: #1C8EA8;
  text-decoration: none;
  margin-right: .5rem;
  font-size: 14px;
}

.st-ticket-number {
  font-weight: 400;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: 0.0025em;
}

.PreviousAndSubmit {
  display: flex;
}

.alignInCenter {
  margin-top:auto;
   margin-bottom:auto;
}

.Previousbutton {
  color: #2AA8C4;
  justify-content: center;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  width: 184.5px;
  height: 35px;
  margin-right: 1rem;
  /* background: linear-gradient(99.23deg, #2AA8C4 -9.01%, #38C1DF 103.32%); */
  border-radius: 11px;
  border: 1px solid #2AA8C4;
  font-family: 'Roboto', sans-serif;
}

.Previousbutton:disabled {
  color: white;
  justify-content: center;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  width: 184.5px;
  height: 35px;
  margin-right: 1rem;
  background: lightgrey;
  border-radius: 11px;
  /* border: 1px solid #2AA8C4; */
  font-family: 'Roboto', sans-serif;
}

.Rephrasebutton {
  color: white;
  justify-content: center;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  width: 184.5px;
  height: 35px;
  margin-right: 1rem;
  background: linear-gradient(99.23deg, #2AA8C4 -9.01%, #38C1DF 103.32%);
  border-radius: 11px;
  /* border: 1px solid #2AA8C4; */
  font-family: 'Roboto', sans-serif;
}

.RephrasebuttonNoBackground {
  color: #1C8EA8;
  justify-content: center;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  width: 184.5px;
  height: 35px;
  margin-right: 1rem;
  border-radius: 11px;
  font-family: 'Roboto', sans-serif;
}

.Rephrasebutton:disabled {
  color: white;
  justify-content: center;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  width: 184.5px;
  height: 35px;
  margin-right: 1rem;
  background: #8B8B8B;
  border-radius: 11px;
  /* border: 1px solid #2AA8C4; */
  font-family: 'Roboto', sans-serif;
}

.selected {
  background-color: #1976d2; /* Change this to your desired background color */
  color: #fff; /* Change this to your desired text color */
}
