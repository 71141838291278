tr#resume-template-tr {
    margin-bottom: 2rem;
  
  }

.settingsHeadingsTypography {
  font-size: 24px;

}

.settingsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width:90%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.italic_font {
  color: #f5a536;
  font-style: italic;
}