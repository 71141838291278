.textfield__opInfo {
    width: 100%;
}

.box__fileUpload {
    border: 1px solid var(--Neutral-Mid-Grey, #8B8B8B);
    background: #F5F5F5;
    justify-content: space-between;
    display: flex;
    padding: 8px;
    align-items: center;
    align-self: stretch;
}